<template>
  <div class="datamessage">
    <van-nav-bar
      :title="$t('DATAMESSAGE')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="tips">{{$t('FILLINTRUEINFORMATION')}}</div>
    <div class="container">
      <van-field :readonly="stopSub" v-model="entityName" :label="$t('ENTITYNAME')" />
      <van-field :readonly="stopSub" v-model="companyAddress" :label="$t('UNITADDRESS')" />
      <van-field :readonly="stopSub" v-model="position" :label="$t('POSITION')" />
      <van-field :readonly="stopSub" v-model="entityPhone" :label="$t('ENTITYPHONE')" />
      <!-- <van-field :readonly="stopSub" :placeholder="$t('PLAEAWORKAGE')" v-model="workAge" type="number" :label="$t('WORKAGE')" /> -->
      <van-field :readonly="stopSub" v-model="line" label="nomor telepon" />
      <van-field :readonly="stopSub" v-model="CurrentResidenceDetails" autosize type="textarea" :label="$t('CURRENTRESIDENCEDETAILS')" />
    </div>
    <div class="tips">{{$t('CONTACTOFIMMEDIATEFAMILY')}}</div>
    <div class="container">
      <van-field :readonly="stopSub" v-model="ContactName" :label="$t('CONTACTNAME')" />
      <van-field :readonly="stopSub" v-model="contactPhone" :label="$t('CONTACTPHONE')" />
      <van-field @focus="showA = true"  :readonly="true" v-model="contactRelation.name" :label="$t('CONTACTRELATION')">
        <!-- <div slot="right-icon"></div> -->
        <van-icon slot="right-icon" name="arrow" />
      </van-field>
      <van-action-sheet v-model="showA" :actions="actionsA" @select="onSelectA" />
    </div>
    <div class="tips">{{$t('OTHER')}}</div>
    <div class="container">
      <van-field :readonly="stopSub" v-model="otherName" :label="$t('CONTACTNAME')" />
      <van-field :readonly="stopSub" v-model="otherPhone" :label="$t('CONTACTPHONE')" />
      <van-field @focus="showB = true"  :readonly="true" v-model="otherRelatio.name" :label="$t('CONTACTRELATION')">
        <van-icon slot="right-icon" name="arrow" />
      </van-field>
      <van-action-sheet v-model="showB" :actions="actionsB" @select="onSelectB" />
    </div>
    <div class="subbox">
      <van-button :disabled="stopSub" @click="sub" class="sub" color="#0097B2" type="primary" block>{{$t('SUBMIT')}}</van-button>
    </div>
  </div>
</template>

<script>
import './css/my.css'
import { Toast } from 'vant'
// saveinformation
import { informationget, relationsList, myInformation, saveinformation } from '@/api/my'
export default {
  name: 'datamessage',
  data () {
    return {
      // 单位名称
      entityName: null,
      // 单位地址
      companyAddress: null,
      // 职位
      position: null,
      // 单位电话
      entityPhone: null,
      // 工龄
      workAge: null,
      // 现居住详细地址
      CurrentResidenceDetails: null,
      // 联系人姓名
      ContactName: null,
      // 联系人手机号
      contactPhone: null,
      // 与联系人的关系
      contactRelation: '',
      // 联系人姓名
      otherName: null,
      // 联系人手机号
      otherPhone: null,
      // 与联系人的关系
      otherRelatio: '',
      // Line：
      line: '',
      // sub
      stopSub: false,
      // 现实下拉框
      showA: false,
      actionsA: [],
      showB: false,
      actionsB: []
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    async sub () {
      if (this.entityName && this.companyAddress && this.position && this.entityPhone &&
      this.CurrentResidenceDetails && this.ContactName && this.contactPhone && this.contactRelation &&
      this.otherName && this.otherPhone && this.otherRelatio && this.line) {
        // console.log(this.contactRelation.name)
        // console.log(this.otherRelatio.name)
        const { data } = await saveinformation({
          companyAddress: this.companyAddress,
          companyName: this.entityName,
          companyPhone: this.entityPhone,
          job: this.position,
          line: this.line,
          relationsSaveReqVOList: [
            {
              name: this.ContactName,
              phone: this.contactPhone,
              relations: this.contactRelation.value,
              type: 1
            },
            {
              name: this.otherName,
              phone: this.otherPhone,
              relations: this.otherRelatio.value,
              type: 2
            }
          ],
          residentialAddress: this.CurrentResidenceDetails
        })
        console.log(data)
        if (data.code === 200) {
          Toast(this.$t('SUBMITSUCCEED'))
          this.stopSub = true
        } else {
          Toast(data.message)
        }
      } else {
        Toast(this.$t('PLEAMESSAGECOMPLETE'))
      }
    },
    async loadData () {
      this.getrelationsList()
      const { data } = await informationget()
      this.entityName = data.data.companyName
      this.companyAddress = data.data.companyAddress
      this.position = data.data.job
      this.entityPhone = data.data.companyPhone
      this.CurrentResidenceDetails = data.data.residentialAddress
      this.line = data.data.line
      // console.log(this.actionsA)
      // console.log(data.data.relationsRspVOList[0])
      // for (const key in data.data.relationsRspVOList) {
      //   if (data.data.relationsRspVOList[key].type === '1') {
      //     console.log(data.data.relationsRspVOList[key].type)
      //     this.contactRelation = data.data.relationsRspVOList[key]
      //   } else {
      //     console.log(data.data.relationsRspVOList[key].type)
      //   }
      // }
      const data1 = await relationsList()
      const arr = data1.data.data
      for (const key in data.data.relationsRspVOList) {
        if (data.data.relationsRspVOList[key].type === '1') {
          for (const k in arr) {
            if (arr[k].value === data.data.relationsRspVOList[key].relations) {
              this.contactRelation = arr[k]
              break
            }
          }
          // console.log(data.data.relationsRspVOList[key])
          this.ContactName = data.data.relationsRspVOList[key].name
          this.contactPhone = data.data.relationsRspVOList[key].phone
        } else {
          for (const k in arr) {
            if (arr[k].value === data.data.relationsRspVOList[key].relations) {
              console.log(arr[k])
              console.log(data.data.relationsRspVOList[key].relations)
              this.otherRelatio = arr[k]
              break
            }
          }
          this.otherName = data.data.relationsRspVOList[key].name
          this.otherPhone = data.data.relationsRspVOList[key].phone
          // this.otherRelatio = data.data.relationsRspVOList[key].relations
        }
      }
    },
    async getmyInformation () {
      const { data } = await myInformation()
      if (data.data.information) {
        // console.log(data.data.information)
        this.stopSub = true
      } else {
        this.stopSub = false
      }
    },
    onSelectA (item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.contactRelation = item
      this.showA = false
    },
    onSelectB (item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.otherRelatio = item
      this.showB = false
    },
    // 获取家人列表
    async getrelationsList () {
      const { data } = await relationsList()
      this.actionsA = data.data
      this.actionsB = data.data
    }
  },
  created () {
    this.loadData()
    this.getmyInformation()
  }
}
</script>

<style scoped>

.tips {
  text-align: center;
  padding: 10px;
  font-size: 13px;
  color: #666;
}
.container {
  box-sizing: border-box;
  padding: 0 10px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  background-color: #fff;
}
.subbox {
  padding: 20px;
}
.sub {
  font-size: 18px;
}
</style>
